@charset "UTF-8";
.clearfix[data-v-2d84e511]::after {
  content: "";
  display: table;
  clear: both;
}
.btn[data-v-2d84e511] {
  cursor: pointer;
}
.pointer[data-v-2d84e511] {
  cursor: pointer;
}
.flex[data-v-2d84e511] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

/* 文字对齐方式 start */
.text-left[data-v-2d84e511] {
  text-align: left;
}
.text-center[data-v-2d84e511] {
  text-align: center;
}
.text-center .ui-button + .ui-button[data-v-2d84e511] {
  margin-left: 0.15625rem;
}
.text-right[data-v-2d84e511] {
  text-align: right;
}

/* 文字对齐方式 end */
.ui-container[data-v-2d84e511] {
  padding-top: calc(1.04167rem);
}
ul[data-v-2d84e511] {
  list-style-type: none; /* 移除列表项的默认标记 */
  padding: 0; /* 移除内边距 */
  margin: 0; /* 移除外边距 */
}
li[data-v-2d84e511] {
  display: block; /* 设置显示为块级元素 */
  padding: 0; /* 移除内边距 */
}

/* 边距 start */
.pd-0[data-v-2d84e511] {
  padding: 0;
}
.pd-10[data-v-2d84e511] {
  padding: 0.05208rem;
}
.pd-lr-20[data-v-2d84e511] {
  padding-left: 0.10417rem;
  padding-right: 0.10417rem;
}
.pd-bt-10[data-v-2d84e511] {
  margin-bottom: 0.05208rem;
}
.pd-bt-30[data-v-2d84e511] {
  margin-bottom: 0.15625rem;
}
.mg-0[data-v-2d84e511] {
  margin: 0;
}
.mg-lt-8[data-v-2d84e511] {
  margin-left: 0.04167rem;
}
.mg-lt-10[data-v-2d84e511] {
  margin-left: 0.05208rem;
}
.mg-lt-20[data-v-2d84e511] {
  margin-left: 0.10417rem;
}
.mg-lt-35[data-v-2d84e511] {
  margin-left: 0.18229rem;
}
.mg-lt-40[data-v-2d84e511] {
  margin-left: 0.20833rem;
}
.mg-lt-60[data-v-2d84e511] {
  margin-left: 0.3125rem;
}
.mg-bt-10[data-v-2d84e511] {
  margin-bottom: 0.05208rem;
}
.mg-bt-15[data-v-2d84e511] {
  margin-bottom: 0.07813rem;
}
.mg-bt-20[data-v-2d84e511] {
  margin-bottom: 0.10417rem;
}
.mg-bt-30[data-v-2d84e511] {
  margin-bottom: 0.15625rem;
}
.mg-bt-40[data-v-2d84e511] {
  margin-bottom: 0.20833rem;
}
.mg-bt-70[data-v-2d84e511] {
  margin-bottom: 0.36458rem;
}
.mg-bt-80[data-v-2d84e511] {
  margin-bottom: 0.41667rem;
}
.mg-bt-110[data-v-2d84e511] {
  margin-bottom: 0.57292rem;
}
.mg-bt-150[data-v-2d84e511] {
  margin-bottom: 0.78125rem;
}
.mg-bt-300[data-v-2d84e511] {
  margin-bottom: 1.5625rem;
}
.mg-bt-400[data-v-2d84e511] {
  margin-bottom: 2.08333rem;
}
.mg-top-0[data-v-2d84e511] {
  margin-top: 0;
}
.mg-top-5[data-v-2d84e511] {
  margin-top: 0.02604rem;
}
.mg-top-10[data-v-2d84e511] {
  margin-top: 0.05208rem;
}
.mg-top-15[data-v-2d84e511] {
  margin-top: 0.07813rem;
}
.mg-top-18[data-v-2d84e511] {
  margin-top: 0.09375rem;
}
.mg-top-20[data-v-2d84e511] {
  margin-top: 0.10417rem;
}
.mg-top-30[data-v-2d84e511] {
  margin-top: 0.15625rem;
}
.mg-top-40[data-v-2d84e511] {
  margin-top: 0.20833rem;
}
.mg-top-50[data-v-2d84e511] {
  margin-top: 0.26042rem;
}
.mg-rt-20[data-v-2d84e511] {
  margin-right: 0.10417rem;
}
.mg-rt-10[data-v-2d84e511] {
  margin-right: 0.05208rem;
}

/* 边距 end */
.layout-wrapper[data-v-2d84e511] {
  height: 100vh;
}
.layout-session[data-v-2d84e511] {
  overflow: hidden;
}
.btn[data-v-2d84e511] {
  cursor: pointer;
}
header h1[data-v-2d84e511] {
  margin: 0;
  float: left;
  font-size: 0.16667rem;
  font-weight: 400;
  height: 100%;
}
header h1 a[data-v-2d84e511] {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}
.el-menu.el-menu--horizontal[data-v-2d84e511] {
  border: 0;
}
a[data-v-2d84e511] {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}
.el-submenu[data-v-2d84e511] {
  height: 100%;
}
.fixed[data-v-2d84e511] {
  position: fixed !important;
  top: 0;
  left: 0;
}
.el-message[data-v-2d84e511] {
  margin-top: 0.3125rem !important;
  min-width: auto; /* 设置最小宽度为自动 */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; /* 设置宽度为内容自适应 */
}
.cursor[data-v-2d84e511] {
  cursor: pointer;
}
.customClass[data-v-2d84e511] {
  border-radius: 0.0625rem;
  width: 1.72917rem;
}
.btn[data-v-2d84e511] {
  cursor: pointer;
}
.btnTruesCopy[data-v-2d84e511] {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.86458rem;
}
.btnFalsesCopy[data-v-2d84e511] {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.86458rem;
}
.btnTrues[data-v-2d84e511] {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.34375rem;
}
.btnFalses[data-v-2d84e511] {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.34375rem;
}
.message-zindex[data-v-2d84e511] {
  z-index: 10000 !important;
}
.event_infomation_card_wrapper[data-v-2d84e511] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.event_infomation_card_wrapper .event_infomation_card[data-v-2d84e511] {
  width: 1.97917rem;
  height: 1.04167rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#efefef));
  background: linear-gradient(180deg, #ffffff 0%, #efefef 100%);
  -webkit-box-shadow: 0 0.02083rem 0.03125rem 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0.02083rem 0.03125rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.05208rem;
  margin-right: 0.15625rem;
  margin-bottom: 0.15625rem;
  border-radius: 10px;
}
.event_infomation_card_wrapper .event_infomation_card[data-v-2d84e511]:nth-child(3n) {
  margin-right: 0;
}
.event_infomation_card_wrapper .event_infomation_card .event_infomation_card_left[data-v-2d84e511] {
  height: 100%;
  width: 0.625rem;
}
.event_infomation_card_wrapper .event_infomation_card .event_infomation_card_left img[data-v-2d84e511] {
  width: 100%;
  height: 100%;
}
.event_infomation_card_wrapper .event_infomation_card .event_infomation_card_right[data-v-2d84e511] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
     -moz-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0.08333rem 0.11458rem;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.event_infomation_card_wrapper .event_infomation_card .event_infomation_card_right .event_infomation_card_title[data-v-2d84e511] {
  height: 0.25rem;
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: bold;
  font-size: 0.09375rem;
  color: #333333;
  line-height: 0.125rem;
  text-align: left;
  font-style: normal;
}
.event_infomation_card_wrapper .event_infomation_card .event_infomation_card_right .event_infomation_card_time[data-v-2d84e511] {
  height: 0.09896rem;
  font-family: MicrosoftYaHei;
  font-size: 0.07292rem;
  color: #999999;
  line-height: 0.09896rem;
  text-align: left;
  font-style: normal;
  margin-bottom: 0.03125rem;
}
.event_infomation_card_wrapper .event_infomation_card .event_infomation_card_right .event_infomation_card_time_value[data-v-2d84e511] {
  height: 0.10417rem;
  font-family: ArialMT;
  font-size: 0.08333rem;
  color: #dd773a;
  line-height: 0.10938rem;
  text-align: left;
  margin-bottom: 0.0625rem;
  font-style: normal;
}
.event_infomation_card_wrapper .event_infomation_card .event_infomation_card_right .event_infomation_card_btn[data-v-2d84e511] {
  height: 0.16667rem;
  background: -webkit-gradient(linear, right top, left top, from(#a4c8dc), to(#96b2d6));
  background: linear-gradient(270deg, #a4c8dc 0%, #96b2d6 100%);
  border-radius: 0.02083rem;
  font-family: MicrosoftYaHei;
  font-size: 0.08333rem;
  color: #ffffff;
  line-height: 0.16667rem;
  text-align: center;
  font-style: normal;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  cursor: pointer;
}
.event_infomation_card_wrapper .event_infomation_card .event_infomation_card_right .ing[data-v-2d84e511] {
  background: -webkit-gradient(linear, right top, left top, from(#f2982c), to(#de783a));
  background: linear-gradient(270deg, #f2982c 0%, #de783a 100%);
}
.event_infomation_card_wrapper .event_infomation_card .event_infomation_card_right .exprise[data-v-2d84e511] {
  background: -webkit-gradient(linear, right top, left top, from(#cdcdcd), to(#c1c1c1));
  background: linear-gradient(270deg, #cdcdcd 0%, #c1c1c1 100%);
}