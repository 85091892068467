@charset "UTF-8";
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
.btn {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

/* 文字对齐方式 start */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-center .ui-button + .ui-button {
  margin-left: 0.15625rem;
}
.text-right {
  text-align: right;
}

/* 文字对齐方式 end */
.ui-container {
  padding-top: calc(1.04167rem);
}
ul {
  list-style-type: none; /* 移除列表项的默认标记 */
  padding: 0; /* 移除内边距 */
  margin: 0; /* 移除外边距 */
}
li {
  display: block; /* 设置显示为块级元素 */
  padding: 0; /* 移除内边距 */
}

/* 边距 start */
.pd-0 {
  padding: 0;
}
.pd-10 {
  padding: 0.05208rem;
}
.pd-lr-20 {
  padding-left: 0.10417rem;
  padding-right: 0.10417rem;
}
.pd-bt-10 {
  margin-bottom: 0.05208rem;
}
.pd-bt-30 {
  margin-bottom: 0.15625rem;
}
.mg-0 {
  margin: 0;
}
.mg-lt-8 {
  margin-left: 0.04167rem;
}
.mg-lt-10 {
  margin-left: 0.05208rem;
}
.mg-lt-20 {
  margin-left: 0.10417rem;
}
.mg-lt-35 {
  margin-left: 0.18229rem;
}
.mg-lt-40 {
  margin-left: 0.20833rem;
}
.mg-lt-60 {
  margin-left: 0.3125rem;
}
.mg-bt-10 {
  margin-bottom: 0.05208rem;
}
.mg-bt-15 {
  margin-bottom: 0.07813rem;
}
.mg-bt-20 {
  margin-bottom: 0.10417rem;
}
.mg-bt-30 {
  margin-bottom: 0.15625rem;
}
.mg-bt-40 {
  margin-bottom: 0.20833rem;
}
.mg-bt-70 {
  margin-bottom: 0.36458rem;
}
.mg-bt-80 {
  margin-bottom: 0.41667rem;
}
.mg-bt-110 {
  margin-bottom: 0.57292rem;
}
.mg-bt-150 {
  margin-bottom: 0.78125rem;
}
.mg-bt-300 {
  margin-bottom: 1.5625rem;
}
.mg-bt-400 {
  margin-bottom: 2.08333rem;
}
.mg-top-0 {
  margin-top: 0;
}
.mg-top-5 {
  margin-top: 0.02604rem;
}
.mg-top-10 {
  margin-top: 0.05208rem;
}
.mg-top-15 {
  margin-top: 0.07813rem;
}
.mg-top-18 {
  margin-top: 0.09375rem;
}
.mg-top-20 {
  margin-top: 0.10417rem;
}
.mg-top-30 {
  margin-top: 0.15625rem;
}
.mg-top-40 {
  margin-top: 0.20833rem;
}
.mg-top-50 {
  margin-top: 0.26042rem;
}
.mg-rt-20 {
  margin-right: 0.10417rem;
}
.mg-rt-10 {
  margin-right: 0.05208rem;
}

/* 边距 end */
.layout-wrapper {
  height: 100vh;
}
.layout-session {
  overflow: hidden;
}
.btn {
  cursor: pointer;
}
header h1 {
  margin: 0;
  float: left;
  font-size: 0.16667rem;
  font-weight: 400;
  height: 100%;
}
header h1 a {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}
.el-menu.el-menu--horizontal {
  border: 0;
}
a {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}
.el-submenu {
  height: 100%;
}
.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
}
.el-message {
  margin-top: 0.3125rem !important;
  min-width: auto; /* 设置最小宽度为自动 */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; /* 设置宽度为内容自适应 */
}
.cursor {
  cursor: pointer;
}
.customClass {
  border-radius: 0.0625rem;
  width: 1.72917rem;
}
.btn {
  cursor: pointer;
}
.btnTruesCopy {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.86458rem;
}
.btnFalsesCopy {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.86458rem;
}
.btnTrues {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.34375rem;
}
.btnFalses {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.34375rem;
}
.login_form {
  width: 1.49479rem;
  min-height: 1.63021rem;
  padding: 0 0.09375rem;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transform: translateY(-0.52083rem);
          transform: translateY(-0.52083rem);
}
.login_form .login_title {
  width: 100%;
  text-align: center;
  height: 0.15104rem;
  font-size: 0.13021rem;
  color: #000000;
  line-height: 0.15104rem;
  letter-spacing: 0.00521rem;
  font-style: normal;
  margin-bottom: 0.17708rem;
}
.login_form .login_tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.13542rem;
}
.login_form .login_tab .tab {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
     -moz-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  text-align: center;
  height: 0.15104rem;
  font-size: 0.06771rem;
  color: #333333;
  line-height: 0.15104rem;
  font-style: normal;
  cursor: pointer;
}
.login_form .login_tab .is_active {
  color: #0d65da;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.login_form .register_role {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.login_form .register_role .register_role_title {
  height: 0.09375rem;
  font-size: 0.06771rem;
  color: #999999;
  line-height: 0.09375rem;
  font-style: normal;
}
.login_form .register_role .register_role_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.05729rem;
  width: 100%;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  margin-bottom: 0.11458rem;
}
.login_form .register_role .register_role_wrapper .register_role_item {
  width: 0.375rem;
  height: 0.14063rem;
  background: #ffffff;
  border-radius: 0.11458rem;
  border: 0.00521rem solid #acd0ff;
  font-size: 0.06771rem;
  color: #3589f6;
  line-height: 0.14063rem;
  font-style: normal;
  text-align: center;
  cursor: pointer;
}
.login_form .register_role .register_role_wrapper .is_active {
  background: #3589f6;
  color: #ffffff;
}
.login_form .el-form-item {
  margin-bottom: 0.14063rem;
}
.login_form .el-input .el-input__inner {
  height: 0.16667rem;
  background: #ffffff;
  line-height: 0.16667rem;
  border-radius: 0.11458rem;
  border: 0.00521rem solid #cecece;
}
.login_form .opraty {
  margin-bottom: 0.11458rem;
  height: 0.08333rem;
  font-size: 0.06771rem;
  color: #999999;
  line-height: 0.08333rem;
  font-style: normal;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
}
.login_form .opraty .opraty_left span:nth-last-child(1) {
  color: #0d65da;
}
.login_form .opraty .opraty_right span {
  color: #0d65da;
}
.login_form .btn {
  height: 0.20313rem;
  line-height: 0.20313rem;
  background: -webkit-gradient(linear, right top, left top, from(#66a9ff), to(#3589f6));
  background: linear-gradient(270deg, #66a9ff 0%, #3589f6 100%);
  border-radius: 0.02083rem;
  font-size: 0.08333rem;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.login_form .suffix_code {
  color: #0d65da;
  margin-right: 0.08333rem;
  font-size: 0.06771rem;
  cursor: pointer;
}
.login_form .mobile .el-input__inner {
  background: #ffffff;
  border-radius: 0 0.11458rem 0.11458rem 0;
  border: 0.00521rem solid #cecece;
}
.login_form .mobile .el-input-group__prepend {
  background: rgb(255, 255, 255);
  border-radius: 0.11458rem 0 0 0.11458rem;
}
.login_form .mobile .el-select {
  width: 0.52083rem;
  font-size: 0.06771rem;
  font-style: normal;
  color: #000;
}
.login_form .mobile .el-select .el-input__icon {
  line-height: 0.16667rem;
}