@charset "UTF-8";
.clearfix[data-v-c46f5450]::after {
  content: "";
  display: table;
  clear: both;
}
.btn[data-v-c46f5450] {
  cursor: pointer;
}
.pointer[data-v-c46f5450] {
  cursor: pointer;
}
.flex[data-v-c46f5450] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

/* 文字对齐方式 start */
.text-left[data-v-c46f5450] {
  text-align: left;
}
.text-center[data-v-c46f5450] {
  text-align: center;
}
.text-center .ui-button + .ui-button[data-v-c46f5450] {
  margin-left: 0.15625rem;
}
.text-right[data-v-c46f5450] {
  text-align: right;
}

/* 文字对齐方式 end */
.ui-container[data-v-c46f5450] {
  padding-top: calc(1.04167rem);
}
ul[data-v-c46f5450] {
  list-style-type: none; /* 移除列表项的默认标记 */
  padding: 0; /* 移除内边距 */
  margin: 0; /* 移除外边距 */
}
li[data-v-c46f5450] {
  display: block; /* 设置显示为块级元素 */
  padding: 0; /* 移除内边距 */
}

/* 边距 start */
.pd-0[data-v-c46f5450] {
  padding: 0;
}
.pd-10[data-v-c46f5450] {
  padding: 0.05208rem;
}
.pd-lr-20[data-v-c46f5450] {
  padding-left: 0.10417rem;
  padding-right: 0.10417rem;
}
.pd-bt-10[data-v-c46f5450] {
  margin-bottom: 0.05208rem;
}
.pd-bt-30[data-v-c46f5450] {
  margin-bottom: 0.15625rem;
}
.mg-0[data-v-c46f5450] {
  margin: 0;
}
.mg-lt-8[data-v-c46f5450] {
  margin-left: 0.04167rem;
}
.mg-lt-10[data-v-c46f5450] {
  margin-left: 0.05208rem;
}
.mg-lt-20[data-v-c46f5450] {
  margin-left: 0.10417rem;
}
.mg-lt-35[data-v-c46f5450] {
  margin-left: 0.18229rem;
}
.mg-lt-40[data-v-c46f5450] {
  margin-left: 0.20833rem;
}
.mg-lt-60[data-v-c46f5450] {
  margin-left: 0.3125rem;
}
.mg-bt-10[data-v-c46f5450] {
  margin-bottom: 0.05208rem;
}
.mg-bt-15[data-v-c46f5450] {
  margin-bottom: 0.07813rem;
}
.mg-bt-20[data-v-c46f5450] {
  margin-bottom: 0.10417rem;
}
.mg-bt-30[data-v-c46f5450] {
  margin-bottom: 0.15625rem;
}
.mg-bt-40[data-v-c46f5450] {
  margin-bottom: 0.20833rem;
}
.mg-bt-70[data-v-c46f5450] {
  margin-bottom: 0.36458rem;
}
.mg-bt-80[data-v-c46f5450] {
  margin-bottom: 0.41667rem;
}
.mg-bt-110[data-v-c46f5450] {
  margin-bottom: 0.57292rem;
}
.mg-bt-150[data-v-c46f5450] {
  margin-bottom: 0.78125rem;
}
.mg-bt-300[data-v-c46f5450] {
  margin-bottom: 1.5625rem;
}
.mg-bt-400[data-v-c46f5450] {
  margin-bottom: 2.08333rem;
}
.mg-top-0[data-v-c46f5450] {
  margin-top: 0;
}
.mg-top-5[data-v-c46f5450] {
  margin-top: 0.02604rem;
}
.mg-top-10[data-v-c46f5450] {
  margin-top: 0.05208rem;
}
.mg-top-15[data-v-c46f5450] {
  margin-top: 0.07813rem;
}
.mg-top-18[data-v-c46f5450] {
  margin-top: 0.09375rem;
}
.mg-top-20[data-v-c46f5450] {
  margin-top: 0.10417rem;
}
.mg-top-30[data-v-c46f5450] {
  margin-top: 0.15625rem;
}
.mg-top-40[data-v-c46f5450] {
  margin-top: 0.20833rem;
}
.mg-top-50[data-v-c46f5450] {
  margin-top: 0.26042rem;
}
.mg-rt-20[data-v-c46f5450] {
  margin-right: 0.10417rem;
}
.mg-rt-10[data-v-c46f5450] {
  margin-right: 0.05208rem;
}

/* 边距 end */
.layout-wrapper[data-v-c46f5450] {
  height: 100vh;
}
.layout-session[data-v-c46f5450] {
  overflow: hidden;
}
.btn[data-v-c46f5450] {
  cursor: pointer;
}
header h1[data-v-c46f5450] {
  margin: 0;
  float: left;
  font-size: 0.16667rem;
  font-weight: 400;
  height: 100%;
}
header h1 a[data-v-c46f5450] {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}
.el-menu.el-menu--horizontal[data-v-c46f5450] {
  border: 0;
}
a[data-v-c46f5450] {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}
.el-submenu[data-v-c46f5450] {
  height: 100%;
}
.fixed[data-v-c46f5450] {
  position: fixed !important;
  top: 0;
  left: 0;
}
.el-message[data-v-c46f5450] {
  margin-top: 0.3125rem !important;
  min-width: auto; /* 设置最小宽度为自动 */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; /* 设置宽度为内容自适应 */
}
.cursor[data-v-c46f5450] {
  cursor: pointer;
}
.customClass[data-v-c46f5450] {
  border-radius: 0.0625rem;
  width: 1.72917rem;
}
.btn[data-v-c46f5450] {
  cursor: pointer;
}
.btnTruesCopy[data-v-c46f5450] {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.86458rem;
}
.btnFalsesCopy[data-v-c46f5450] {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.86458rem;
}
.btnTrues[data-v-c46f5450] {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.34375rem;
}
.btnFalses[data-v-c46f5450] {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.34375rem;
}
.layout_user_wrapper[data-v-c46f5450] {
  float: right;
  height: 0.1875rem;
  padding-top: 0.08333rem;
  min-width: 0.625rem;
}
.layout_user_wrapper .user_link[data-v-c46f5450] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.layout_user_wrapper .person[data-v-c46f5450] {
  width: 0.625rem;
  height: 0.1875rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  position: relative;
}
.layout_user_wrapper .person .person_img[data-v-c46f5450] {
  width: 0.1875rem;
  height: 0.1875rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 133;
}
.layout_user_wrapper .person .person_img img[data-v-c46f5450] {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.layout_user_wrapper .person .person_no_login[data-v-c46f5450] {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 0.14583rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.02083rem 0.08333rem 0.03125rem 0.23958rem;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 0.09375rem;
  font-family: MicrosoftYaHei;
  font-size: 0.0625rem;
  color: #ffffff;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  font-style: normal;
}
.layout_user_wrapper .person .person_no_login .body[data-v-c46f5450] {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 0.14583rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.02083rem 0.08333rem 0.03125rem 0.23958rem;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  background: -webkit-gradient(linear, right top, left top, from(#c3d2db), to(#ffffff));
  background: linear-gradient(270deg, #c3d2db 0%, #ffffff 100%);
  border-radius: 0.09375rem;
  opacity: 0.4;
  pointer-events: none;
}
.el-dropdown-menu[data-v-c46f5450] {
  width: 0.625rem;
  text-align: center;
  z-index: 99999 !important;
}