@charset "UTF-8";
.clearfix[data-v-e340c4b0]::after {
  content: "";
  display: table;
  clear: both;
}
.btn[data-v-e340c4b0] {
  cursor: pointer;
}
.pointer[data-v-e340c4b0] {
  cursor: pointer;
}
.flex[data-v-e340c4b0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

/* 文字对齐方式 start */
.text-left[data-v-e340c4b0] {
  text-align: left;
}
.text-center[data-v-e340c4b0] {
  text-align: center;
}
.text-center .ui-button + .ui-button[data-v-e340c4b0] {
  margin-left: 0.15625rem;
}
.text-right[data-v-e340c4b0] {
  text-align: right;
}

/* 文字对齐方式 end */
.ui-container[data-v-e340c4b0] {
  padding-top: calc(1.04167rem);
}
ul[data-v-e340c4b0] {
  list-style-type: none; /* 移除列表项的默认标记 */
  padding: 0; /* 移除内边距 */
  margin: 0; /* 移除外边距 */
}
li[data-v-e340c4b0] {
  display: block; /* 设置显示为块级元素 */
  padding: 0; /* 移除内边距 */
}

/* 边距 start */
.pd-0[data-v-e340c4b0] {
  padding: 0;
}
.pd-10[data-v-e340c4b0] {
  padding: 0.05208rem;
}
.pd-lr-20[data-v-e340c4b0] {
  padding-left: 0.10417rem;
  padding-right: 0.10417rem;
}
.pd-bt-10[data-v-e340c4b0] {
  margin-bottom: 0.05208rem;
}
.pd-bt-30[data-v-e340c4b0] {
  margin-bottom: 0.15625rem;
}
.mg-0[data-v-e340c4b0] {
  margin: 0;
}
.mg-lt-8[data-v-e340c4b0] {
  margin-left: 0.04167rem;
}
.mg-lt-10[data-v-e340c4b0] {
  margin-left: 0.05208rem;
}
.mg-lt-20[data-v-e340c4b0] {
  margin-left: 0.10417rem;
}
.mg-lt-35[data-v-e340c4b0] {
  margin-left: 0.18229rem;
}
.mg-lt-40[data-v-e340c4b0] {
  margin-left: 0.20833rem;
}
.mg-lt-60[data-v-e340c4b0] {
  margin-left: 0.3125rem;
}
.mg-bt-10[data-v-e340c4b0] {
  margin-bottom: 0.05208rem;
}
.mg-bt-15[data-v-e340c4b0] {
  margin-bottom: 0.07813rem;
}
.mg-bt-20[data-v-e340c4b0] {
  margin-bottom: 0.10417rem;
}
.mg-bt-30[data-v-e340c4b0] {
  margin-bottom: 0.15625rem;
}
.mg-bt-40[data-v-e340c4b0] {
  margin-bottom: 0.20833rem;
}
.mg-bt-70[data-v-e340c4b0] {
  margin-bottom: 0.36458rem;
}
.mg-bt-80[data-v-e340c4b0] {
  margin-bottom: 0.41667rem;
}
.mg-bt-110[data-v-e340c4b0] {
  margin-bottom: 0.57292rem;
}
.mg-bt-150[data-v-e340c4b0] {
  margin-bottom: 0.78125rem;
}
.mg-bt-300[data-v-e340c4b0] {
  margin-bottom: 1.5625rem;
}
.mg-bt-400[data-v-e340c4b0] {
  margin-bottom: 2.08333rem;
}
.mg-top-0[data-v-e340c4b0] {
  margin-top: 0;
}
.mg-top-5[data-v-e340c4b0] {
  margin-top: 0.02604rem;
}
.mg-top-10[data-v-e340c4b0] {
  margin-top: 0.05208rem;
}
.mg-top-15[data-v-e340c4b0] {
  margin-top: 0.07813rem;
}
.mg-top-18[data-v-e340c4b0] {
  margin-top: 0.09375rem;
}
.mg-top-20[data-v-e340c4b0] {
  margin-top: 0.10417rem;
}
.mg-top-30[data-v-e340c4b0] {
  margin-top: 0.15625rem;
}
.mg-top-40[data-v-e340c4b0] {
  margin-top: 0.20833rem;
}
.mg-top-50[data-v-e340c4b0] {
  margin-top: 0.26042rem;
}
.mg-rt-20[data-v-e340c4b0] {
  margin-right: 0.10417rem;
}
.mg-rt-10[data-v-e340c4b0] {
  margin-right: 0.05208rem;
}

/* 边距 end */
.layout-wrapper[data-v-e340c4b0] {
  height: 100vh;
}
.layout-session[data-v-e340c4b0] {
  overflow: hidden;
}
.btn[data-v-e340c4b0] {
  cursor: pointer;
}
header h1[data-v-e340c4b0] {
  margin: 0;
  float: left;
  font-size: 0.16667rem;
  font-weight: 400;
  height: 100%;
}
header h1 a[data-v-e340c4b0] {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}
.el-menu.el-menu--horizontal[data-v-e340c4b0] {
  border: 0;
}
a[data-v-e340c4b0] {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}
.el-submenu[data-v-e340c4b0] {
  height: 100%;
}
.fixed[data-v-e340c4b0] {
  position: fixed !important;
  top: 0;
  left: 0;
}
.el-message[data-v-e340c4b0] {
  margin-top: 0.3125rem !important;
  min-width: auto; /* 设置最小宽度为自动 */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; /* 设置宽度为内容自适应 */
}
.cursor[data-v-e340c4b0] {
  cursor: pointer;
}
.customClass[data-v-e340c4b0] {
  border-radius: 0.0625rem;
  width: 1.72917rem;
}
.btn[data-v-e340c4b0] {
  cursor: pointer;
}
.btnTruesCopy[data-v-e340c4b0] {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.86458rem;
}
.btnFalsesCopy[data-v-e340c4b0] {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.86458rem;
}
.btnTrues[data-v-e340c4b0] {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.34375rem;
}
.btnFalses[data-v-e340c4b0] {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.34375rem;
}
.banner_bar_wrapper[data-v-e340c4b0] {
  width: 100%;
  height: 0.88542rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.banner_bar_wrapper .banner_bar_item[data-v-e340c4b0] {
  width: 2.13542rem;
  height: 100%;
  padding: 0.19271rem 0.46875rem 0.21875rem 0.16146rem;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.banner_bar_wrapper .banner_bar_item .item_top[data-v-e340c4b0] {
  width: 100%;
  height: 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0.45313rem;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.banner_bar_wrapper .banner_bar_item .item_title[data-v-e340c4b0] {
  height: 0.10417rem;
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: bold;
  font-size: 0.10417rem;
  color: #333333;
  line-height: 0.10417rem;
  text-align: left;
  font-style: normal;
}
.banner_bar_wrapper .banner_bar_item .item_sub_title_wrapper[data-v-e340c4b0] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
     -moz-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 0.07292rem;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.banner_bar_wrapper .banner_bar_item .item_sub_title_wrapper .item_sub_title[data-v-e340c4b0] {
  margin-right: 0.07292rem;
  height: 0.07292rem;
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: bold;
  font-size: 0.07292rem;
  color: rgba(51, 51, 51, 0.3);
  line-height: 0.07292rem;
  text-align: left;
  font-style: normal;
}
.banner_bar_wrapper .banner_bar_item .actice[data-v-e340c4b0] {
  color: #333333 !important;
}
.banner_bar_wrapper .banner_bar_item .item_content[data-v-e340c4b0] {
  margin-top: 0.07813rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
     -moz-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.banner_bar_wrapper .banner_bar_item .item_content .item[data-v-e340c4b0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 0.10417rem;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.04167rem;
}
.banner_bar_wrapper .banner_bar_item .item_content .item .left[data-v-e340c4b0] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
     -moz-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-family: MicrosoftYaHei;
  font-size: 0.07292rem;
  color: #0d65da;
  text-align: left;
  font-style: normal;
  padding-left: 0.09375rem;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
.banner_bar_wrapper .banner_bar_item .item_content .item .left[data-v-e340c4b0]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0.0625rem;
  height: 0.0625rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAJFJREFUKFOlkT0OggAMhb/iLnHX1TN4ACZGPIS7JCaewMQEdw6hIxMH4AysuBvdpYa/IEw0dGze9/raCsYSo54hcNQtwgXwWqMU5cxN8s64BxpxBqxGU18ouw7qgVDvQICQ4HCooZIYxQceRLKvWv/AG1iyYMNVnjVw0jVfCuBDJO5swBjJvHQV0HTWiR80f/oHChg6DSCtm7gAAAAASUVORK5CYII=) no-repeat;
  background-size: contain;
}
.banner_bar_wrapper .banner_bar_item .item_content .item .right[data-v-e340c4b0] {
  width: 0.3125rem;
  height: 0.10417rem;
  background: #2c71d8;
  line-height: 0.10417rem;
  border-radius: 0.02083rem;
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: bold;
  font-size: 0.0625rem;
  color: #ffffff;
  font-style: normal;
  text-align: center;
}