@charset "UTF-8";
.clearfix[data-v-6f134224]::after {
  content: "";
  display: table;
  clear: both;
}
.btn[data-v-6f134224] {
  cursor: pointer;
}
.pointer[data-v-6f134224] {
  cursor: pointer;
}
.flex[data-v-6f134224] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

/* 文字对齐方式 start */
.text-left[data-v-6f134224] {
  text-align: left;
}
.text-center[data-v-6f134224] {
  text-align: center;
}
.text-center .ui-button + .ui-button[data-v-6f134224] {
  margin-left: 0.15625rem;
}
.text-right[data-v-6f134224] {
  text-align: right;
}

/* 文字对齐方式 end */
.ui-container[data-v-6f134224] {
  padding-top: calc(1.04167rem);
}
ul[data-v-6f134224] {
  list-style-type: none; /* 移除列表项的默认标记 */
  padding: 0; /* 移除内边距 */
  margin: 0; /* 移除外边距 */
}
li[data-v-6f134224] {
  display: block; /* 设置显示为块级元素 */
  padding: 0; /* 移除内边距 */
}

/* 边距 start */
.pd-0[data-v-6f134224] {
  padding: 0;
}
.pd-10[data-v-6f134224] {
  padding: 0.05208rem;
}
.pd-lr-20[data-v-6f134224] {
  padding-left: 0.10417rem;
  padding-right: 0.10417rem;
}
.pd-bt-10[data-v-6f134224] {
  margin-bottom: 0.05208rem;
}
.pd-bt-30[data-v-6f134224] {
  margin-bottom: 0.15625rem;
}
.mg-0[data-v-6f134224] {
  margin: 0;
}
.mg-lt-8[data-v-6f134224] {
  margin-left: 0.04167rem;
}
.mg-lt-10[data-v-6f134224] {
  margin-left: 0.05208rem;
}
.mg-lt-20[data-v-6f134224] {
  margin-left: 0.10417rem;
}
.mg-lt-35[data-v-6f134224] {
  margin-left: 0.18229rem;
}
.mg-lt-40[data-v-6f134224] {
  margin-left: 0.20833rem;
}
.mg-lt-60[data-v-6f134224] {
  margin-left: 0.3125rem;
}
.mg-bt-10[data-v-6f134224] {
  margin-bottom: 0.05208rem;
}
.mg-bt-15[data-v-6f134224] {
  margin-bottom: 0.07813rem;
}
.mg-bt-20[data-v-6f134224] {
  margin-bottom: 0.10417rem;
}
.mg-bt-30[data-v-6f134224] {
  margin-bottom: 0.15625rem;
}
.mg-bt-40[data-v-6f134224] {
  margin-bottom: 0.20833rem;
}
.mg-bt-70[data-v-6f134224] {
  margin-bottom: 0.36458rem;
}
.mg-bt-80[data-v-6f134224] {
  margin-bottom: 0.41667rem;
}
.mg-bt-110[data-v-6f134224] {
  margin-bottom: 0.57292rem;
}
.mg-bt-150[data-v-6f134224] {
  margin-bottom: 0.78125rem;
}
.mg-bt-300[data-v-6f134224] {
  margin-bottom: 1.5625rem;
}
.mg-bt-400[data-v-6f134224] {
  margin-bottom: 2.08333rem;
}
.mg-top-0[data-v-6f134224] {
  margin-top: 0;
}
.mg-top-5[data-v-6f134224] {
  margin-top: 0.02604rem;
}
.mg-top-10[data-v-6f134224] {
  margin-top: 0.05208rem;
}
.mg-top-15[data-v-6f134224] {
  margin-top: 0.07813rem;
}
.mg-top-18[data-v-6f134224] {
  margin-top: 0.09375rem;
}
.mg-top-20[data-v-6f134224] {
  margin-top: 0.10417rem;
}
.mg-top-30[data-v-6f134224] {
  margin-top: 0.15625rem;
}
.mg-top-40[data-v-6f134224] {
  margin-top: 0.20833rem;
}
.mg-top-50[data-v-6f134224] {
  margin-top: 0.26042rem;
}
.mg-rt-20[data-v-6f134224] {
  margin-right: 0.10417rem;
}
.mg-rt-10[data-v-6f134224] {
  margin-right: 0.05208rem;
}

/* 边距 end */
.layout-wrapper[data-v-6f134224] {
  height: 100vh;
}
.layout-session[data-v-6f134224] {
  overflow: hidden;
}
.btn[data-v-6f134224] {
  cursor: pointer;
}
header h1[data-v-6f134224] {
  margin: 0;
  float: left;
  font-size: 0.16667rem;
  font-weight: 400;
  height: 100%;
}
header h1 a[data-v-6f134224] {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}
.el-menu.el-menu--horizontal[data-v-6f134224] {
  border: 0;
}
a[data-v-6f134224] {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}
.el-submenu[data-v-6f134224] {
  height: 100%;
}
.fixed[data-v-6f134224] {
  position: fixed !important;
  top: 0;
  left: 0;
}
.el-message[data-v-6f134224] {
  margin-top: 0.3125rem !important;
  min-width: auto; /* 设置最小宽度为自动 */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; /* 设置宽度为内容自适应 */
}
.cursor[data-v-6f134224] {
  cursor: pointer;
}
.customClass[data-v-6f134224] {
  border-radius: 0.0625rem;
  width: 1.72917rem;
}
.btn[data-v-6f134224] {
  cursor: pointer;
}
.btnTruesCopy[data-v-6f134224] {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.86458rem;
}
.btnFalsesCopy[data-v-6f134224] {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.86458rem;
}
.btnTrues[data-v-6f134224] {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.34375rem;
}
.btnFalses[data-v-6f134224] {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.34375rem;
}
.page_card_wrapper[data-v-6f134224],
.page_card_wrapper_dark[data-v-6f134224] {
  width: 100%;
}
.page_card_wrapper .page_card_title[data-v-6f134224],
.page_card_wrapper_dark .page_card_title[data-v-6f134224] {
  width: 100%;
  height: 0.16667rem;
  font-family: MicrosoftYaHei;
  font-size: 0.14583rem;
  color: #333333;
  line-height: 0.16667rem;
  text-align: center;
  font-style: normal;
  margin-bottom: 0.07292rem;
}
.page_card_wrapper .desc[data-v-6f134224],
.page_card_wrapper_dark .desc[data-v-6f134224] {
  margin-bottom: 0.13542rem;
}
.page_card_wrapper_dark .page_card_title[data-v-6f134224] {
  color: #fff;
}