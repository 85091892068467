@charset "UTF-8";
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.btn {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

/* 文字对齐方式 start */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}
.text-center .ui-button + .ui-button {
  margin-left: 0.15625rem;
}

.text-right {
  text-align: right;
}

/* 文字对齐方式 end */
.ui-container {
  padding-top: calc(1.04167rem);
}

ul {
  list-style-type: none; /* 移除列表项的默认标记 */
  padding: 0; /* 移除内边距 */
  margin: 0; /* 移除外边距 */
}

li {
  display: block; /* 设置显示为块级元素 */
  padding: 0; /* 移除内边距 */
}

/* 边距 start */
.pd-0 {
  padding: 0;
}

.pd-10 {
  padding: 0.05208rem;
}

.pd-lr-20 {
  padding-left: 0.10417rem;
  padding-right: 0.10417rem;
}

.pd-bt-10 {
  margin-bottom: 0.05208rem;
}

.pd-bt-30 {
  margin-bottom: 0.15625rem;
}

.mg-0 {
  margin: 0;
}

.mg-lt-8 {
  margin-left: 0.04167rem;
}

.mg-lt-10 {
  margin-left: 0.05208rem;
}

.mg-lt-20 {
  margin-left: 0.10417rem;
}

.mg-lt-35 {
  margin-left: 0.18229rem;
}

.mg-lt-40 {
  margin-left: 0.20833rem;
}

.mg-lt-60 {
  margin-left: 0.3125rem;
}

.mg-bt-10 {
  margin-bottom: 0.05208rem;
}

.mg-bt-15 {
  margin-bottom: 0.07813rem;
}

.mg-bt-20 {
  margin-bottom: 0.10417rem;
}

.mg-bt-30 {
  margin-bottom: 0.15625rem;
}

.mg-bt-40 {
  margin-bottom: 0.20833rem;
}

.mg-bt-70 {
  margin-bottom: 0.36458rem;
}

.mg-bt-80 {
  margin-bottom: 0.41667rem;
}

.mg-bt-110 {
  margin-bottom: 0.57292rem;
}

.mg-bt-150 {
  margin-bottom: 0.78125rem;
}

.mg-bt-300 {
  margin-bottom: 1.5625rem;
}

.mg-bt-400 {
  margin-bottom: 2.08333rem;
}

.mg-top-0 {
  margin-top: 0;
}

.mg-top-5 {
  margin-top: 0.02604rem;
}

.mg-top-10 {
  margin-top: 0.05208rem;
}

.mg-top-15 {
  margin-top: 0.07813rem;
}

.mg-top-18 {
  margin-top: 0.09375rem;
}

.mg-top-20 {
  margin-top: 0.10417rem;
}

.mg-top-30 {
  margin-top: 0.15625rem;
}

.mg-top-40 {
  margin-top: 0.20833rem;
}

.mg-top-50 {
  margin-top: 0.26042rem;
}

.mg-rt-20 {
  margin-right: 0.10417rem;
}

.mg-rt-10 {
  margin-right: 0.05208rem;
}

/* 边距 end */
.layout-wrapper {
  height: 100vh;
}

.layout-session {
  overflow: hidden;
}

.btn {
  cursor: pointer;
}

header h1 {
  margin: 0;
  float: left;
  font-size: 0.16667rem;
  font-weight: 400;
  height: 100%;
}
header h1 a {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}

.el-menu.el-menu--horizontal {
  border: 0;
}

a {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}

.el-submenu {
  height: 100%;
}

.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
}

.el-message {
  margin-top: 0.3125rem !important;
  min-width: auto; /* 设置最小宽度为自动 */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; /* 设置宽度为内容自适应 */
}

.cursor {
  cursor: pointer;
}

.customClass {
  border-radius: 0.0625rem;
  width: 1.72917rem;
}

.btn {
  cursor: pointer;
}

.btnTruesCopy {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.86458rem;
}

.btnFalsesCopy {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.86458rem;
}

.btnTrues {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.34375rem;
}

.btnFalses {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.34375rem;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.btn {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

/* 文字对齐方式 start */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}
.text-center .ui-button + .ui-button {
  margin-left: 0.15625rem;
}

.text-right {
  text-align: right;
}

/* 文字对齐方式 end */
.ui-container {
  padding-top: calc(1.04167rem);
}

ul {
  list-style-type: none; /* 移除列表项的默认标记 */
  padding: 0; /* 移除内边距 */
  margin: 0; /* 移除外边距 */
}

li {
  display: block; /* 设置显示为块级元素 */
  padding: 0; /* 移除内边距 */
}

/* 边距 start */
.pd-0 {
  padding: 0;
}

.pd-10 {
  padding: 0.05208rem;
}

.pd-lr-20 {
  padding-left: 0.10417rem;
  padding-right: 0.10417rem;
}

.pd-bt-10 {
  margin-bottom: 0.05208rem;
}

.pd-bt-30 {
  margin-bottom: 0.15625rem;
}

.mg-0 {
  margin: 0;
}

.mg-lt-8 {
  margin-left: 0.04167rem;
}

.mg-lt-10 {
  margin-left: 0.05208rem;
}

.mg-lt-20 {
  margin-left: 0.10417rem;
}

.mg-lt-35 {
  margin-left: 0.18229rem;
}

.mg-lt-40 {
  margin-left: 0.20833rem;
}

.mg-lt-60 {
  margin-left: 0.3125rem;
}

.mg-bt-10 {
  margin-bottom: 0.05208rem;
}

.mg-bt-15 {
  margin-bottom: 0.07813rem;
}

.mg-bt-20 {
  margin-bottom: 0.10417rem;
}

.mg-bt-30 {
  margin-bottom: 0.15625rem;
}

.mg-bt-40 {
  margin-bottom: 0.20833rem;
}

.mg-bt-70 {
  margin-bottom: 0.36458rem;
}

.mg-bt-80 {
  margin-bottom: 0.41667rem;
}

.mg-bt-110 {
  margin-bottom: 0.57292rem;
}

.mg-bt-150 {
  margin-bottom: 0.78125rem;
}

.mg-bt-300 {
  margin-bottom: 1.5625rem;
}

.mg-bt-400 {
  margin-bottom: 2.08333rem;
}

.mg-top-0 {
  margin-top: 0;
}

.mg-top-5 {
  margin-top: 0.02604rem;
}

.mg-top-10 {
  margin-top: 0.05208rem;
}

.mg-top-15 {
  margin-top: 0.07813rem;
}

.mg-top-18 {
  margin-top: 0.09375rem;
}

.mg-top-20 {
  margin-top: 0.10417rem;
}

.mg-top-30 {
  margin-top: 0.15625rem;
}

.mg-top-40 {
  margin-top: 0.20833rem;
}

.mg-top-50 {
  margin-top: 0.26042rem;
}

.mg-rt-20 {
  margin-right: 0.10417rem;
}

.mg-rt-10 {
  margin-right: 0.05208rem;
}

/* 边距 end */
.layout-wrapper {
  height: 100vh;
}

.layout-session {
  overflow: hidden;
}

.btn {
  cursor: pointer;
}

header h1 {
  margin: 0;
  float: left;
  font-size: 0.16667rem;
  font-weight: 400;
  height: 100%;
}
header h1 a {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}

.el-menu.el-menu--horizontal {
  border: 0;
}

a {
  color: #333;
  text-decoration: none;
  display: block;
  height: 100%;
}

.el-submenu {
  height: 100%;
}

.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
}

.el-message {
  margin-top: 0.3125rem !important;
  min-width: auto; /* 设置最小宽度为自动 */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; /* 设置宽度为内容自适应 */
}

.cursor {
  cursor: pointer;
}

.customClass {
  border-radius: 0.0625rem;
  width: 1.72917rem;
}

.btn {
  cursor: pointer;
}

.btnTruesCopy {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.86458rem;
}

.btnFalsesCopy {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.86458rem;
}

.btnTrues {
  background: #3d6bca !important;
  border: 0.00521rem solid #3d6bca;
  color: #fff;
  float: right;
  margin-right: 0.34375rem;
}

.btnFalses {
  background: #fff !important;
  border: 0.00521rem solid #3d6bca;
  color: #4974ce;
  float: left;
  margin-left: 0.34375rem;
}